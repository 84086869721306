@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

   
:root {
    /* background-color: #770000; */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, button, input {
    font-family: "Be Vietnam Pro", sans-serif;
    font-weight: 400;
    font-style: normal;
}

body {
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}
